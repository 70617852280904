import { useParams } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from '../shared/styles/appStyles';
import RegisterView from './RegisterView';


function RegisterContainer(props) {

    const { token, trialUser } = useParams();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <RegisterView trialUser={trialUser} token={token}/>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default RegisterContainer
