import React, { Suspense, useEffect } from 'react';
import { List } from "immutable";
import { ProductFruits } from 'react-product-fruits';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from 'react-redux';
import routes from './shared/configs/routes_c';
import * as Sentry from '@sentry/react';
import { Route, Routes} from "react-router-dom";
// components
import Page404 from "./shared/components/Page404";
import NavBarContainer from "./nav/NavContainer";
import Loading from "./shared/components/SpinnerLoading";
import PrivateRoute from "./shared/auth/PrivateRoute";
import Snackbar from "./shared/components/Snackbar";
import { catchBlockErrors } from "./shared/utils/sentry";
import { useLayersUpdateHook } from './pages/skyview/map_layers/layersUpdateHook';

// Util vars
const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const PROJECT_FRUIT_CODE = process.env.REACT_APP_PRODUCT_FRUITS;

const AppWrapper = styled("main")(({ theme }) => ({
    bottom: 0,
    display: "block",
    left: 0,
    overflowY: "auto",
    position: "fixed", // fixed to size of viewport
    right: 0,
    top: 0,
    zIndex: 0,
    background: theme.palette.primary.offWhite
}));

const MainContentContainer = styled("section")({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: "100%",
    marginLeft: "88px",
    position: "relative"
});


function Fruits({appLoading}){
    const firstName = useSelector((state) =>
        state.app.getIn(["user", "name", "firstName"], "")
    );
    const lastName = useSelector((state) =>
        state.app.getIn(["user", "name", "lastName"], "")
    );
    const userID = useSelector((state) => state.app.getIn(["user", "_id"], ""));
    const username = useSelector((state) =>
        state.app.getIn(["user", "username"], "")
    );
    const email = useSelector((state) =>
        state.app.getIn(["user", "email"], "")
    );
    const orgID = useSelector((state) =>
        state.app.getIn(["user", "organizationID"], "none")
    );
    const signUpAt = useSelector((state) =>
        state.app.getIn(["user", "registered_at"], "")
    );
    const hasJobSharing = useSelector((state) => {
        const _hasJobSharing = state.app.getIn(["user", "pages"], List([]))
            .filter(page => {
                return page.get('_id', '') === 'job_share'
            });

        return _hasJobSharing.size === 1;
    })
    const hasSRODefaults = useSelector((state) => {
        const _hasSRODefaults = state.app.getIn(["user", "pages"], List([]));

        return _hasSRODefaults.size > 2;
    })


    const userInfo = {
        username: userID,
        firstname: firstName,
        lastname: lastName,
        signUpAt: signUpAt,
        email: email,
        props: {
            username_auth0: username,
            ordID: orgID,
            hasJobSharing,
            hasSRODefaults,

        }
    }

    if (!userID || appLoading || orgID === 'none') return null;
    return (
        <ProductFruits 
            workspaceCode={PROJECT_FRUIT_CODE}
            language="en" 
            user={userInfo}
        />
    )

};

function App(props) {

    // For Intercom widget
    const pages = useSelector((state) =>
        state.app.getIn(["user", "pages"], List([]))
    );
    const firstName = useSelector((state) =>
        state.app.getIn(["user", "name", "firstName"], "")
    );
    const lastName = useSelector((state) =>
        state.app.getIn(["user", "name", "lastName"], "")
    );
    const intercomHash = useSelector((state) =>
        state.app.getIn(["user", "intercom_hash"], "")
    );
    const userID = useSelector((state) => state.app.getIn(["user", "_id"], ""));
    const username = useSelector((state) =>
        state.app.getIn(["user", "username"], "")
    );
    const email = useSelector((state) =>
        state.app.getIn(["user", "email"], "")
    );
    const orgName = useSelector((state) =>
        state.app.getIn(["organization", "name"], "none")
    );

    useLayersUpdateHook();


    useEffect(() => {
        return () => {
            window?.productFruits?.services?.destroy()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Display Intercom widget
        if (intercomHash) {
            try {
                window.Intercom("boot", {
                    app_id: APP_ID,
                    user_id: userID, // User ID from get_user call
                    user_hash: intercomHash, // HMAC using SHA-256 from backend
                    name: `${firstName} ${lastName}`,
                    alignment: 'right'
                });
            } catch (error) {
                catchBlockErrors({
                    error,
                    "file/function": "App.js intercom boot"
                });
            }
        }
        // clears Intercom cookie
        return () => window.Intercom("shutdown");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intercomHash]);


    useEffect(() => {
        if (username) {
            const _userObj = { id: userID, username, email, orgName };
            Sentry.configureScope((scope) => {
                scope.setUser(_userObj);
            });
            Sentry.configureScope((scope) => {
                scope.setTag("User", username);
                scope.setTag("Org", orgName);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, orgName]);

    if (pages.size === 0) return;
    return (
        <>
            <Fruits />
            <CssBaseline />
            <AppWrapper>
                <Snackbar />
                <NavBarContainer />
                <Suspense fallback={<div><Loading/></div>}>
                    <MainContentContainer>
                        <Routes>
                            {pages.map((page) => {
                                const pageID = page.get('_id', '');
                                const route = routes[pageID];
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={
                                            <PrivateRoute
                                                tool={route.tool}
                                                message={route.errorMessage}
                                                component={route.component}
                                            />
                                        }
                                    />
                                );
                            })}
                            <Route
                                path='/*'
                                element={
                                    <PrivateRoute
                                        tool={'404'}
                                        message={'BUG PAGE 404'}
                                        component={Page404}
                                    />
                                }
                            />
                        </Routes>
                    </MainContentContainer>
                </Suspense>
            </AppWrapper>
        </>
    );
}
//{/* </Router> */}

export default App;
