import { combineReducers } from 'redux';
// reducers
import app from './app_s';
import nonIM from './non_immutable_s';
import skyview from '../../pages/skyview/store/skyview_s';
// import retro from '../../pages/skyview/retro/store';
import equipment from '../../pages/equipment/store/equipment_s';
import reports from '../../pages/reports/store/reports_s';
import reporting from '../../pages/reporting/store/reporting_s';
import templates from '../../pages/templates/store/templates_s';
import newReport from '../../pages/new_report_modal/store';
import dotReport from '../../pages/dot/store';

// import newReport from '../components/newReportModal/store/newReport_s';

export default combineReducers({
    app,
    skyview,
    // retro,
    equipment,
    nonIM,
    reports,
    templates,
    reporting,
    newReport,
    dotReport,
});
