import { fromJS, List } from 'immutable';
import { equipmentActionTypes } from './equipmentActionTypes';


// types
// export const equipmentActionTypes = {
//     'equipment/setAllEQ': 'equipment/setAllEQ',
//     'equipment/updateEquipmentTable': 'equipment/updateEquipmentTable',
//     'equipment/update': 'equipment/update',
//     'equipment/updateFromEquipmentPage':'equipment/updateFromEquipmentPage', 
// };

// actions
export const setAllEquipmentData = (allEquipment) => ({
    type: equipmentActionTypes['equipment/setAllEQ'],
    allEquipment
});

export const updateEquipment = (equipment) => ({
    type: equipmentActionTypes['equipment/update'],
    equipment
});

const initialState = fromJS({
    allEquipment: [],
    tableData: [],

});

const equipmentReducer = (state=initialState, action) => {
    switch(action.type){

        case equipmentActionTypes['equipment/setAllEQ']:
            const allEquipment = action.allEquipment;
            let allEquipmentState = state.set('allEquipment', allEquipment);

            const tableEquipmentData = allEquipment.map(equipment => {
                let calibrationLogArray = equipment.get(
                    "calibration_log", List([])
                );
                const mostRecentCalibration = calibrationLogArray === null 
                    ? "" 
                    : calibrationLogArray.getIn([ calibrationLogArray.size - 1, "timestamp" ], "");

                calibrationLogArray = calibrationLogArray === null ? List([]) : calibrationLogArray;

                const share_location = equipment.get("share_location", "");
                const job_sharing = equipment.get('job_sharing', '');

                const lastUsed = equipment.get('timestamp', '');

                const serial_number = equipment.get(
                    'serial_number', 'no_serial_number'
                );
                const _status = equipment.get('is_painting', 'not_painting');
                // const status = _status === 'painting' ? 'Painting' : 'Not Painting';
                const status = equipment.get('status', 'none')
                const color = _status === 'painting' ? 'green' : 'blue';
                const name = equipment.get('name', 'No Name');
                const eagle_eye = equipment.get('eagle_eye', false);
                const location = equipment.getIn(['last_location', 'coordinates'], []);
                const sro_linked_hardware_id = equipment
                    .get('sro_linked_hardware_id', '');
                // const location = [];
                const type = equipment.get('type', 'no type');
                const _logger_type = equipment.get("logger_type", "");
                // some logger_types are returned as "Pigeon" or "pdg"; when edited on 
                // the FE it becomes "pdg". This object captures both options and 
                // returns "pdg" to the frontend to be properly displayed
                const loggerTypeObj = {
                    "DL-09": "dl09", 
                    "dl09": "dl09",
                    "DL-12": "dl12",
                    "dl12": "dl12",
                    "DL-18": "dl18",
                    "dl18": "dl18",
                    "HDVO": "hdvo",
                    "hdvo": "hdvo",
                    "Pigeon": "pdg",
                    "pdg": "pdg",
                    "VX10": "vx10",
                    "vx10": "vx10"
                }
                const logger_type = loggerTypeObj[_logger_type];

                return (
                    fromJS({
                        sro_linked_hardware_id,
                        id: serial_number,
                        eagle_eye,
                        mostRecentCalibration,
                        calibrationLogArray,
                        color,
                        lastUsed,
                        logger_type,
                        serial_number,
                        job_sharing,
                        share_location,
                        status,
                        name,
                        type,
                        location
                    })
                )
            });

            allEquipmentState = allEquipmentState.set('tableData', tableEquipmentData);



            return allEquipmentState;

        case equipmentActionTypes['equipment/update']:
            let _equipment = action.equipment;


            let _allEquipment = state.get('allEquipment', List([]));
            const _equipmentID  = _equipment.get('serial_number', 'not_found');

            const isExistingEquipment = _allEquipment.findIndex(e => (
                e.get('serial_number', '') === _equipmentID
            ));

            if (isExistingEquipment !== -1) {
                // checking for bad coords and dropping them if we find them.
                const coords = _equipment.getIn(['last_location', 'coordinates'], List([]));

                // if both coords are 0 0 then those are bad and we want to
                // delete them
                if (coords.get(0, 0) === 0 && coords.get(1, 0) === 0) {
                    _equipment = _equipment.delete('last_location');
                }

                const updatedEquipment = _allEquipment.get(isExistingEquipment).merge(_equipment);
                _allEquipment = _allEquipment.setIn([isExistingEquipment], updatedEquipment);

            } 

            return state.set('allEquipment', _allEquipment);

        case equipmentActionTypes['equipment/updateFromEquipmentPage']:
            const apiResponse = action.apiResponse;
            const _tableData = state.get('tableData', List([]));
            let _newTableData = '';
            
            const equipmentIndex = _tableData.findIndex(equipment => {
                return equipment.get("serial_number", null) === apiResponse.get("serial_number", "")
            })
            if (equipmentIndex !== -1) {
                const updatedEquipment = _tableData.get(equipmentIndex, "").merge(apiResponse);
                _newTableData = _tableData.set(equipmentIndex, updatedEquipment);
            }
            
            if (_newTableData) {
                return state.set('tableData', _newTableData);
            } else return state;
            
        default:
            return state
    }
};


export default equipmentReducer;
