import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { Paper, Typography, Button } from "@mui/material";
import cautionUnderConstruction from "../../images/cautionUnderConstruction.jpg";
import spr_image from "../../images/SPR_online_red-01.png";
import image from "../../images/image.png";
import { bugPageReportToSentry } from "../utils/sentry";


class MainContainerErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            eventId: null,
            url: null,
            gameTab: false,
            error: { name: "No name" },
        };
    }
    static getDerivedStateFromError(error) {
        console.error(error);
        return {
            hasError: true,
            error: error,
        };
    }

    toggleGameTab = () => {
        this.setState({ gameTab: !this.state.gameTab });
    };

    handleErrorReport = () => {
        Sentry.showReportDialog({ eventId: this.state.eventId });
    };

    componentDidCatch(error, info) {
        this.setState({ url: window.location.href });
        const { message, category } = this.props;
        if (error && error.name !== 'ChunkLoadError') {
            bugPageReportToSentry({error, message, category, info});
        }
    }

    componentDidUpdate() {
        // Check to see if we have left the bugged page
        // This only works for when we leave the page
        // Need to figure out how to set this up so it will work on a smaller level
        if (this.state.url && window.location.href !== this.state.url) {
            this.setState({ hasError: false, url: null });
        }
    }

    render() {
        const { error, hasError } = this.state;

        // Bug Page Error
        if (hasError && error && error.name !== "ChunkLoadError") {
            return (
                <div
                    style={{
                        width: "100%",
                        height: "70%",
                        marginLeft: "10em",
                        display: "flex",
                        marginTop: "10%",
                        justifyContent: "center",
                    }}
                >
                    <img
                        style={{ cursor: "pointer" }}
                        height="300"
                        width="300"
                        src={cautionUnderConstruction}
                        alt="under construction sign"
                    />
                    <div style={{ maxWidth: "50%", marginLeft: "3em" }}>
                        <Typography variant="h5">
                            This tool is currently in maintenance mode.
                            Our team has identified a problem and are
                            working to resolve it as soon as possible.
                            Issues are normally taken care of within an
                            hour. We apologize for any inconvenience.
                        </Typography>
                        <Typography variant="subtitle1">
        Our Development Team Email ::{" "}
        <b>support@spec-rite.io</b>
    </Typography>
</div>
                </div>
            );
            // Chunk Load Error from after an updated when old files are no
            // longer avaliable
        } else if (hasError && error && error.name === "ChunkLoadError") {
            return (
                <Paper
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{ cursor: "pointer", marginTop: "10%" }}
                        width="50%"
                        src={spr_image}
                        alt="Spec-rite Logo"
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h4">
                            Spec-Rite Online has been updated!
                        </Typography>
                        <Typography variant="h5">
                            Please refresh to get the latest version.
                        </Typography>
                        <Button
                            style={{ width: "5em", margin: "1em" }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Refresh
                        </Button>
                    </div>
                </Paper>
            );
        }
        // If no Error is returns the wrapped component
        return this.props.children;
    }
}

MainContainerErrorBoundary.propTypes = {
    message: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
};

export default MainContainerErrorBoundary;
