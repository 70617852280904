import {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import $ from 'jquery';
import styles from './formStyles.module.css';

//<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
//<script>
//  hbspt.forms.create({
//    region: "na1",
//    portalId: "5862347",
//    formId: "fcc9ceaf-994b-4efe-82bc-ff4cf886c8cf"
//  });



const BACKEND_URL = process.env.REACT_APP_SPEC_RITE_URL || "";

async function apiCallForCode({email, equipmentCode, handleResponse, script}){
    const url = (`${BACKEND_URL}challenge?` + new URLSearchParams({
        challenge: equipmentCode,
        email
    }));

    await fetch(url)
        .then(response => response.json())
        .then(data => {
            handleResponse(data);
        })
        .catch(error => {
            console.log('error', error);
            this.setState({ error, isLoading: false })
        })

}

function TruckCodeContainer(props){

    let [responseCode, setResponseCode] = useState('');
    let [isLoading, setIsLoading] = useState(false);
    let [errorMessage, setError] = useState(null);

    const handleFormReset = () => window.location.reload();

    const handleResponse = (data) => {
        const collection = document.getElementsByClassName("hbspt-form");
        const formEl = collection[0];
        formEl.style.display = 'none';

        const code = data?.data || '';
        const message = data?.message || '';

        if (!code && message){
            setError(message);
        }

        // var externalResource = document.getElementById('hubspot_script');
        // externalResource?.parentNode?.removeChild(externalResource);
        setIsLoading(false);
        setResponseCode(code);
        // setResponseCode(code);
    };

    useEffect(() => {
        if (!document || !document.body) return;

        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        script.id = 'hubspot_script';
        document.body.appendChild(script);

        setResponseCode('');
        script.addEventListener('load', (event) => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "5862347",
                    formId: "fcc9ceaf-994b-4efe-82bc-ff4cf886c8cf",
                    // css: cssClass,
                    // cssClass: styles.formStyles,
                    // submitButtonClass: styles.button,
                    // target: '#hubspotForm'
                    onFormSubmit: function(form){
                        const data = $(form)
                            .serializeArray()
                            .reduce(function (json, { name, value }) {
                                json[name] = value;
                                return json;
                            }, {});

                        const email = data?.email;
                        const equipmentCode = data?.equipment_code;

                        setIsLoading(true);
                        const collection = document.getElementsByClassName("hbspt-form");
                        apiCallForCode({
                            email, 
                            equipmentCode,
                            handleResponse,
                            script,
                        });


                    },
                })

                const collection = document.getElementsByClassName("hbspt-form");

                for (let i = 0; i < collection.length; i++) {
                    collection[i].style.width = "50%";
                    collection[i].style.margin = "1em auto";
                }
            }
        });
        return () => {
            document.body.remove(script);
            // var externalResource = document.getElementById('hubspot_script');
            // externalResource?.parentNode?.removeChild(externalResource);
            // // script.remove();
        };
    }, []);


    return (
        <Box
            sx={{
                maxWidth: '90vw',
            }}

        >
            <div id="hubspotForm" />
            {responseCode && errorMessage === null && (
                <Box
                    sx={{
                        margin: '5em auto',
                        width: '80%',
                    }}
                >
                    <Typography variant="h5">
                        Thank you! This temporary registration code will extend the life of your device by 20 hours. Enter the code on your device to resume painting immediately. A Skip-Line support member will reach out to the email you provided with your permanent registration code. For issues please contact support@skipline.com
                    </Typography>
                    <Typography 
                        sx={{
                            marginTop: '1em',
                        }}
                        variant="h4"
                    >
                        You temporary code is: <b>{responseCode}</b>
                    </Typography>
                </Box>
            )}
            {errorMessage !== null && (
                <Box
                    sx={{
                        width: '80%',
                        justifyContent: 'space-evenly',
                        height: '13em',
                        alignItems: 'center',
                        margin: '5em auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h3">
                        Whoops something went wrong
                    </Typography>
                    <Typography 
                        sx={{
                        }}
                        variant="h5"
                    >
                        {errorMessage}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#e5c220',
                            width: '20%',
"&:hover":{

    backgroundColor: '#e5c220',
    border: '2px solid light-grey',
}
}}
                        onClick={handleFormReset}
                    >
                        Reload Form
                    </Button>
                </Box>
            )}
        </Box>
    );
};


export default TruckCodeContainer;

//                <Box
//                    sx={{
//                        display: 'flex',
//                        alignItems: 'baseline',
//                    }}
//                >
//                    <TextField
//                        onChange={handleInput}
//                        sx={{
//                            width: '20em',
//                        }}
//                        id="code"
//                        label="Equipment Code"
//                        type="search"
//                        helperText="Enter your equipments code to recieve your temporary registartion code"
//                    />
//                    <Button
//                        onClick={handleSubmit}
//                        sx={{
//                            height: '3em',
//                            marginLeft: '3em',
//                        }}
//                        variant="contained"
//                    >
//                        Submit
//                    </Button>
//                </Box>
//                {responseCode !== '' && (
//                    <Typography 
//                        sx={{
//                            marginTop: '2em',
//                        }}
//                        variant="h5"
//                    >
//                        Registration Code: {responseCode}
//                    </Typography>
//                )}
