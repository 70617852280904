import { DateTime } from 'luxon';
import { List } from "immutable";
import { catchBlockErrors } from "../utils/sentry";


// When saving a users layers, we have to convert the dates to strings before we
// make the post request
export const convertDatesInLayers = (layers=List([])) => {
    let convertedLayers = layers;
    try {
        convertedLayers = convertedLayers.map(layer => {
            const datesKeyPath = ['dateRange', 'dates'];
            const datesArray = layer.getIn(datesKeyPath, List([]));
            const updatedDatesArray = datesArray.map(date => DateTime.fromISO(date));
            const updatedLayer = layer.setIn(datesKeyPath, updatedDatesArray);
            return updatedLayer;
        })
    } catch(error){
        catchBlockErrors({
            error,
            'file/function': 'convertDatesInLayers/convertDatesInLayers'
        })
    } finally {
        return convertedLayers;
    }
};
