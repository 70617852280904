import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid, List, ListItemText, Link, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import logo from '../../images/SPR_online_red-01.png';

const useStyles = makeStyles({
    loginWrapper: {
        width: "100%",
        margin: "10%",
        textAlign: "center"
    },
    buttonTopMargin: {
        marginTop: "5%"
    },
    button: {
        background: '#ce142d'
    },
    wrapper: {
        height: '100vh', 
        background: 'black'
    },
    white: {
        color: "white"
    }
})


const LoginPage = (props) => {

    const classes = useStyles();

    let  location = useLocation();

    const { loginWithRedirect } = useAuth0();
    const pathname = location.pathname;
    const search = location.search || '';
    let path = pathname + search
    path = path === '/' ? '/' : path;

    return (
        <Grid container className={classes.wrapper}>
            <div className={classes.loginWrapper}>
                <Grid item xs={12}>
                    <img src={logo} alt="Spec-rite logo" width={"100%"} />
                </Grid>
                <Grid item xs={12} style={{marginTop: "5%"}}>
                    <Button
                        color="primary"
                        onClick={() => loginWithRedirect({ appState: { targetUrl: path } })}
                        variant="contained"
                        className={classes.button}
                    >
                        Login
                    </Button>
                </Grid>
            </div>
            <div style={{ margin: "auto" }}>
                <List style={{ display: "flex" }}>
                    <ListItem>
                        <ListItemText>
                            <Link href="https://go.spec-rite.io/spec-riteonline" className={classes.white}>
                                About
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText className={classes.white}>
                            contact@spec&#8209;rite.io
                        </ListItemText>
                    </ListItem>
                </List>
            </div>
        </Grid >
    )
}

export default LoginPage;
