import SideBarContainer from './SideBarContainer';

import { Grid } from '@mui/material';


function NavBarContainer(props) {
    return (
        <Grid item xs={12}>
            <SideBarContainer />
        </Grid>
    );
}

export default NavBarContainer;
