import { createTheme, adaptV4Theme } from '@mui/material/styles';


// Light and Main colors 
const PRIMARY = '#326C85';
const SECONDARY = '#3A8A7A';
const TERTIARY = '#3F4F97';
const ERROR = '#B3261E';
const BACKGROUND = '#FBFEFF';

const CONTAINER_SECONDARY = '#B8DFDA';
// Dark Theme colors
const DARK_PRIMARY = 'rgba(0, 65, 88, 1)';
const DARK_SECONDARY = '#8CCCC2';
const DARK_TERTIARY = '#A0AACF';
const DARK_ERROR = '#F2B8B5';
const DARK_BACKGROUND = '#1C1B1F';

const WHITE = '#FFFFFF';


const RED = '#CF2031';
const GREEN = '#037C3B';
const BLUE = '#1B3D9F';
const DARK_GREY = '#A2A2A2';
const ON_SURFACE_GREY = '#AEAAAE';
// const LIGHT_GREY = '#DDDDDD';
const LIGHT_GREY = 'rgba(31, 31, 31, 0.12)';
const OFF_WHITE = '#FAFAFA';
const BLACK = '#212121';
const OPTIONS_GREY_BACKGROUND = "#E5E5E5";

// take theme and pass it to makeStyles inside a component
// theme is passed via a Material-UI React Context provider in Root.js
// export const theme = createTheme(adaptV4Theme({
export const theme = createTheme({
    // have to use given keys associated with theme object
    // i.e. palette, overrides, breakpoints, etc.
    palette: {
        mode: "light",
        primary: {
            main: PRIMARY,
            light: PRIMARY,
            dark: DARK_PRIMARY,

        },
        secondary: {
            main: SECONDARY,
            light: SECONDARY,
            dark: DARK_SECONDARY,
            container: CONTAINER_SECONDARY,

        },
        error: {
            main: ERROR,
            light: ERROR,
            dark: DARK_ERROR,

        },
        background: {
            default: BACKGROUND,
            paper: BACKGROUND,
            options: OPTIONS_GREY_BACKGROUND,
        },
        success: {
            main: SECONDARY,
        },
        info: {
            main: TERTIARY,
        },

    },
    // Pass in any global overrides for specific Material-UI components
    overrides: {
        MuiButton: {
            label: {
                // Only capitalizes first letter of word; overrides capitalizing all letters
                textTransform: 'capitalize' 
            }
        },
    },
    typography: {
        color: BLACK,
        fontFamily: [
            'Red Hat Display',
            'Montserrat',
            'Roboto',
            'Helevetica',
            'sans-serif'
        ].join(','),
        h1: {
            fontSize: '24px',
            lineHeight: '29px',
        },
        h2: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '16px',
            lineHeight: '20px',
        },
        h4: {
            fontSize: '14px',
            lineHeight: '17px',
        },
        h5: {
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 600,
        },
        h6: {
            fontSize: '12px',
            lineHeight: '15px',
            fontWeight: 600,
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                // The props to change the default for.
                disableRipple: true, // No more ripple!
            },
        },

        MuiButton: {

            variants: [
                {
                    props: { variant: 'red' },
                    style: {
                        backgroundColor: RED,
                        '&:hover': {
                            backgroundColor: RED,

                        }
                    },
                },
                {
                    props: { variant: 'white' },
                    style: {
                        backgroundColor: OFF_WHITE,
                        color: 'black',
                        '&:hover': {
                            backgroundColor: OFF_WHITE,

                        }
                    },
                },
            ],
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    // fontSize: '1rem',
                    textTransform: 'capitalize',
                    // backgroundColor: BLUE,
                    backgroundColor: PRIMARY,
                    // borderColor: LIGHT_GREY,
                    color: WHITE,
                    '&:hover': {
                        border: 'none',
                        outline: `solid 3px ${LIGHT_GREY}`,
                        backgroundColor: PRIMARY,
                        // "boxShadow": "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                    },
                    '&:disabled': {
                        backgroundColor: LIGHT_GREY,
                        borderColor: DARK_GREY,
                        color: ON_SURFACE_GREY,
                        '&:hover': {
                            // border: 'none',
                            // outline: `solid 3px ${LIGHT_GREY}`,
                            // backgroundColor: BLUE,
                        }

                    },
                }
            }
        }
    }
});

const appStyle = theme => ({
    // Used for centering Spinner/Loading indicator
    centerInParentDiv: {
        position: "absolute",
        width: "95%",
        height: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        zIndex: 999,
    }
});

export default appStyle;
