import React, { useState } from 'react';
import Immutable from 'immutable';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import successImage from '../images/andy_thumbs_up.jpg'
import { catchBlockErrors, reportAPIErrorToSentry } from '../shared/utils/sentry';
import makeStyles from '@mui/styles/makeStyles';
// Components
import Dialog from '@mui/material/Dialog';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Paper,
    TextField,
    Typography,
    Grid,
    LinearProgress,
} from '@mui/material/';
import logo from '../images/logo-for-auth0.png';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8%'
    },
});

const baseURL = process.env.REACT_APP_SPEC_RITE_URL || "";


function RegisterView(props) {

    const classes = useStyles();
    const navigate = useNavigate();

    // const { token } = useParams();
    const params = useParams();
    const token = params.token

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [consent, setConsent] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [registerError, setRegisterError] = useState("");

    const enabled = username && password && consent;

    // const closeDialog = () => setIsRegistered(false);

    const handleChange = (event) => {

        const id = event.target.id || null;
        let value = event.target.value || "";

        // default function just in case something goes wrong
        let callback = () => { };

        if (id === 'userName') callback = setUsername;
        if (id === 'password') callback = setPassword;
        if (id === 'tos') {
            callback = setConsent;
            value = !consent;
        }

        callback(value);

    }

    const handleRegister = async () => {
        try {
            setLoading(true);

            await axios({
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                method: "post",
                baseURL,
                url: `register_user`,
                data: {
                    username,
                    password,
                    token,
                    consent
                }
            })
                .then(response => {
                    if (response && response.status && response.status === 201) {
                        setIsRegistered(true);
                        setTimeout(() => {
                            navigate('/');
                        }, 2000)
                    }
                    return response
                })
                .catch(error => {

                    const _error = error.response && error.response.data
                        ? error.response.data
                        : error.response

                    const errorResponse = Immutable.fromJS(_error);

                    const errorMessage = errorResponse.getIn(
                        ['message'], 'Failed to Register'
                    )
                    // report registration error to sentry
                    reportAPIErrorToSentry({
                        backendResponse: errorMessage,
                        category: error.config.method,
                        token: token,
                        error,
                    });

                    setRegisterError(errorMessage);
                    return error;
                })


        } catch (error) {
            catchBlockErrors({ error, fileFunction: 'RegisterView/handleRegister' });
        } finally {
            setLoading(false);
        }

    }

    return (
        <>
                <Grid container style={{ justifyContent: "center", margin: "5em auto" }}>
                    <Grid item xs={10} sm={8} md={5}>
                        <Paper className={classes.form}>
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src={logo}
                                    alt={"Spec-rite logo"}
                                    height='100px'
                                    width='100px'
                                />
                            </div>
                            <Typography
                                variant='h6'
                                style={{ margin: '5% 0', textAlign: "center" }}
                            >
                                Welcome to Spec-Rite Online!
                            </Typography>
                            <Typography>Username</Typography>
                            <TextField
                                autoFocus={true}
                                helperText='A username may be between 1 and 15 characters long'
                                id='userName'
                                margin='dense'
                                onChange={handleChange}
                                value={username}
                                variant='outlined'
                                type='text'
                            />
                            <Typography>Password</Typography>
                    <TextField
                        helperText={
                                    <>
                                        Must contain special characters (!@#$%^&*)<br></br>
                                        Must contain lower cases (a-z), upper cases (A-Z) and numbers (0-9)<br></br>
                                        Must have 8 characters in length
                                    </>
                        }
                        id='password'
                        margin='dense'
                        onChange={handleChange}
                        value={password}
                        variant='outlined'
                        type='password'
                    />
                    <Button
                        disabled={(!enabled || loading || isRegistered)}
                        onClick={handleRegister}
                        variant='contained'
                        color='primary'
                        style={{ marginTop: '20px' }}
                    >
                        Register
                            </Button>
                    {loading && <LinearProgress />}
                    <Link
                        href={
                            "https://skipline.com/spec-rite-online-platform-terms/"
                        }
                        target="_blank"
                        rel='noreferrer noopener'
                    >
                        Terms of Service
                            </Link>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                id={"tos"}
                                checked={consent}
                                onChange={handleChange}
                            />
                        )}
                        label="Agree to Terms of Service"
                    />
                    <Dialog
                        open={isRegistered}
                    >
                        <div 
                            style={{
                                height: '70%',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                        <Typography
                            variant="h5"
                            style={{
                                textAlign: "center",
                                fontWeight: 'bold',
                                padding: '.5em',
                                margin: '2em',
                                borderBottom: '2px solid black'
                            }}
                        >
                            Registration Was Sucessful!
                        </Typography>
                        <img 
                            src={successImage} 
                            style={{height: '50%', width: '50%'}} 
                            alt="Andy thumbs up"
                        />
                        <Typography
                            variant="h6"
                            style={{
                                textAlign: "center",
                                fontWeight: 'bold',
                                padding: '1em',
                                margin: '0 auto',
                            }}
                        >
                            You will now be redirected to our login page!
                        </Typography>
                        <Typography
                            style={{
                                textAlign: "center",
                                margin: '0 auto',
                            }}
                        >
                            If you are not redirected after a few seconds please <br/>
                            <a href={window.location.origin}>Click Here.</a>
                        </Typography>
                    </div>
                    </Dialog>
                    {registerError && (
                        <Typography
                            style={{
                                fontSize: "1rem",
                                color: 'red',
                                padding: '.5em',
                                textAlign: "center",
                                margin: '0 auto',
                            }}
                        >
                            {registerError}
                        </Typography>
                    )}
                        </Paper>
                    </Grid>
                </Grid>
                )
            }
        </>
    )
}

export default RegisterView;
