import { DateTime, Info } from 'luxon';
import { catchBlockErrors } from '../utils/sentry';


function OrganizationDateTime(){
    this.today = DateTime.local();
    this.timeZone = null;
};

OrganizationDateTime.prototype.setTimeZone = function(timeZone) { 
    try {
        let _timeZone = timeZone;

        // if the timeZone isn't valid, use the local time of the user instead

        if (!Info.isValidIANAZone(_timeZone)){
            catchBlockErrors({error: `timezone invalid ${_timeZone}`, 'fileFunction': 'date.js/setTimeZone'});
            _timeZone = DateTime.local().zoneName;
        }
        // const today = DateTime.local().setZone(_timeZone)
        let today = DateTime.local().setZone(_timeZone).set({hour: 0, minute: 0});

        this.today = today;
        this.timeZone = _timeZone;
    } catch(error) {
        catchBlockErrors({error, 'fileFunction': 'date.js/setTimeZone'});
    };
};


export const dateRanges = {
    'yst': 'yesterday',
    'wtd': 'week',
    'mtd': 'month',
    'ytd': 'year',
    'quarter': 'quarter',
    'today': null,
    'custom': null,
};


OrganizationDateTime.prototype.getToday = function(includeTime=false){
    try {
        if (includeTime) {
            return this.today
                // .toISO({ 
                //     includeOffset: false,
                //     suppressSeconds: true,
                //     suppressMilliseconds: true
                // })
                // .startOf("minute")
        }
        // turn the object into a string ie: '2017-09-14T03:20:34.091-04:00'
        // split it into an array at the T and take the first index ie: '2017-09-14'
        return this.today.toString().split("T")[0];
    } catch(error) {
        catchBlockErrors({error, 'fileFunction': 'date.js/todayBasedOnTimeZone'});
        // if an error happens, report the error and the time zone passed
        // then to keep the application working return date local to users
        // browser
        return DateTime.local().toString().split("T")[0];
    };
};


OrganizationDateTime.prototype.getFirstOfMonthYearWeek = function(
    range,
    includeTime=false,
){
    try {
        const _range = dateRanges[range];
        // we default to today as being the range
        let returnDate = this.today
        if (_range !== null) {
            returnDate = this.today.startOf(_range);
        }
        if (includeTime){
            return returnDate
                // .startOf("minute")
                // .toISO({ 
                //     includeOffset: false,
                //     suppressSeconds: true,
                //     suppressMilliseconds: true
                // });
        }
        return returnDate.toString().split("T")[0];;

    } catch(error) {
        // if an error happens, report the error and the time zone passed
        // Just to keep the app from breaking lets return today for now
        catchBlockErrors({error, 'fileFunction': 'date.js/getFirstOfMonthYearWeek'});
        return this.today.toString().split("T")[0];
    }
};

OrganizationDateTime.prototype.getDatesFromRange = function(
    range,
    dates=[],
    returnAsDateObj=false,
    includeTime=false,

){
    try {
        let today = this.today;
        let endDate = today;

        // if range is custom, the dates will be passed along with the range.
        if (range === 'custom' && dates.length){
            today = dates[1];
            endDate = dates[0];
            return [endDate, today]
        };
        if(range === 'yst'){
            today = this.today.minus({days: 1});
            endDate = this.today.minus({days: 1});
            // return [endDate, today];
        } else {

            // range can be either a number or string.
            // if it is a string we pull out the full week year month value
            // from the dateRange object.
            const _range = dateRanges[range];

            // if range is a number we just subtract that number from todays date.
            if (Number.isInteger(parseInt(range))) {
                endDate = today.minus({days: range});
                // otherwise we use the string from the dateRange object to just the
                // first of the week month or year. 
            } else if (_range !== null){
                endDate = today.startOf(_range);
            }
        }
        if (returnAsDateObj) return [endDate.set({hour: 0}), today.set({hour: 23})];
        if (includeTime){
            return [
                endDate.set({hour: 0}),
                today.set({hour: 23})

            ];
        };
        return [endDate.toString().split("T")[0], today.toString().split("T")[0]];

    } catch(error) {
        // if an error happens, report the error and the time zone passed
        // Just to keep the app from breaking lets return today for now
        catchBlockErrors({error, 'fileFunction': 'date.js/getDatesFromDateRange'});
        return this.today.toString().split("T")[0];
    }
};


OrganizationDateTime.prototype.convertDates = function(
    dates,
    formatString
){
    const newDates = dates.map(date => {
        const _date = DateTime.fromFormat(date, formatString, {zone: this.timeZone});
        return _date;
    });

    return newDates;

};




export default OrganizationDateTime;


//const minus30 = DateTime.local().setZone(timeZone).minus({days: 30});
//       console.log('minus30', minus30.toString());
