import React, { Suspense, useEffect, useState } from 'react';
import { List } from "immutable";
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from  'react-router';
import { createStore, applyMiddleware } from 'redux';
import PrivateRoute from "./shared/auth/PrivateRoute";
import { Route, Routes, useLocation} from "react-router-dom";
import ReduxThunk from 'redux-thunk'
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { fetchAndSetUserAndOrgInfo } from "./shared/stores/app_s";

import rootReducer from './shared/stores/root_s';
import _API from './shared/API';
import  OrganizationDateTime from './shared/dates';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './shared/styles/appStyles';
import LoginPage from './shared/auth/LoginPage';
import { catchBlockErrors } from './shared/utils/sentry';
import App from './App';
import Loading from "./shared/components/SpinnerLoading";
import Page404 from "./shared/components/Page404";

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

// export the api class after we pass it dispatch
export const API = new _API(store.dispatch);
export const OrgDateTime = new OrganizationDateTime();

function LoadingRoute(props){
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAppLoading, setAppLoading] = useState(true);
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const pages = useSelector((state) =>
        state.app.getIn(["user", "pages"], List([]))
    );
    const closeAppLoading = () => setAppLoading(false);

    useEffect(() => {
        if (user && isAuthenticated) {
            try {
                (async () => {
                    // user is the user we get back from auth0
                    const userId = user.sub.split("|")[1];
                    const token = await getAccessTokenSilently();

                    // set the token on the API class here so we don't even need to
                    // use it again
                    API.setToken(token);

                    const permissions = jwt_decode(token).permissions;

                    await dispatch(
                        fetchAndSetUserAndOrgInfo(userId, permissions)
                    );
                })();
            } catch (error) {
                catchBlockErrors({
                    error,
                    "file/function": "App.js fetch set users data"
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user]);

    useEffect(() => {
        if (pages.size === 0) return;
        const defaultPage = pages.getIn([0, 'link'], '');
        if (defaultPage) {
            const finalLink = location === "/" ? defaultPage : location;
            closeAppLoading();
            navigate(finalLink);
        }

    }, [pages]);

    if (isAppLoading ) {
        return <Loading />
    }
    return (
        <App {...props} />
    )
};

function Root(props) {
    const { isLoading, isAuthenticated } = useAuth0();

    try {
        if (isLoading) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (!isAuthenticated) {
            return <LoginPage />;
        }

    } catch (error) {
        catchBlockErrors({ error, fileFunction: "Root/second one" });
        return <LoginPage />;
    }

    // only return the app once we are authenticated
    return isAuthenticated && (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route
                            path="/*"
                            element={
                                <LoadingRoute 
                                    {...props} 
                                />
                            }
                        />
                    </Routes>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}


export default Root;
