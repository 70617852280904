import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { List } from "immutable";
import { removeSnackbar } from "../stores/app_s";
import MuiSnackbar from "@mui/material/Snackbar";

function Snackbar(props) {
    const dispatch = useDispatch();

    const message = useSelector((state) =>
        state.app.getIn(["notifications", 0, "message"], "")
    );

    const onClose = () => {
        dispatch(removeSnackbar());
    };

    return (
        <MuiSnackbar
            open={message ? true : false}
            autoHideDuration={2000}
            onClose={onClose}
            message={message}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
    );
}
export default Snackbar;
