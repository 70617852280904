import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useLocation } from "react-router-dom";
import MainContainerErrorBoundary from '../components/MainContainerErrorBoundary';

function PrivateRoute({
    component: Component,
    tool,
    message,
    path,
    ...rest
}) {

    const { isAuthenticated } = useAuth0();
    const location = useLocation();

    if (!isAuthenticated) {
        return (
            <Navigate
                replace
                to={{
                    pathname: "/",
                    state: { from: location }
                }}            
                />
        )
    }

    return (
            <MainContainerErrorBoundary
                tool={tool}
                message={message}
                category={'BUG PAGE'}
            >
                <Component {...rest}/>
            </MainContainerErrorBoundary>
    );
}


export default PrivateRoute;
