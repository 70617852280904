import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from  'react-router';
import { useAuth0 } from "@auth0/auth0-react";


function Page404(props) {
    const navigate = useNavigate();
    const link = useSelector((state) => {
     return state.app.getIn(["user", "pages", 0, "link"], '/')
    });
    const { isLoading, isAuthenticated } = useAuth0();
    useEffect(() => {
        if (isLoading || !isAuthenticated) return;
        navigate(link);
    }, [isLoading, isAuthenticated]);
}


export default Page404
