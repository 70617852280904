import React from 'react';
import { createRoot } from 'react-dom/client';
import specRiteLogo from './images/logo-for-auth0.png';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import {
    BrowserRouter, 
    Route, 
    Routes,
    useNavigate,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,

} from 'react-router-dom';
import RegisterContainer from './register/RegisterContainer';
import TruckCodeContainer from './truck_registration/TruckCodeContainer';
import { BrowserTracing } from '@sentry/tracing';

import Root from './Root';

import { Auth0Provider } from "@auth0/auth0-react";

import config from "./shared/configs/auth0_c.js";

// Setting up Sentry
const RELEASE = process.env.REACT_APP_VERSION_RELEASE;
const ENVIRONMENT = process.env.REACT_APP_SENTRY_ENV;

if (process.env.NODE_ENV === 'production') {
    console.log = function(){};
    Sentry.init({
        dsn: 'https://4257496b73c44d539df29e39055bddec@sentry.io/1462207',
        release: RELEASE,
        environment: ENVIRONMENT,
        integrations: [
            new Sentry.Integrations.Breadcrumbs({ console: false }),
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        normalizeDepth: 10,
    })
}


function AppWrapper(props){
    const navigate = useNavigate();
    const onRedirectCallback = appState => {
        const path = appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        navigate(path);
    };

    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
                audience: config.apiIdentifier,
                redirect_uri: window.location.origin,
                scope: config.scope
            }}
        >
            <Routes>
                <Route
                    path='/register'
                    element={<TruckCodeContainer />}
                />
                <Route
                    path='/register_user/:trialUser/:token'
                    element={<RegisterContainer />}
                />
                <Route path="/*" element={<Root />} />
            </Routes>
        </Auth0Provider>
    )
};
// A function that routes the user to the right place
// after login

const options = {
    theme: {
        logo: specRiteLogo,
    },
    languageDictionary: {
        title: "Spec-Rite"
    }
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <AppWrapper />
    </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
