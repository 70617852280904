import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';

// Global acronym ECI = Error Caught In



// Report Backend Request Errors To Sentry
const reportAPIErrorToSentry = ({
    error=null,  //  The error object
    category='', // What type of call was it POST : GET
    backendResponse='' // the message from the backend
}) => {
    try {
        const messageSTR = JSON.stringify(backendResponse);
        Sentry.addBreadcrumb({
            category: category,
            message: messageSTR,
            level: 'warning',
        });
        if (error) { 
            Sentry.captureMessage(messageSTR)
            Sentry.captureException(error);
        };
    } catch(error) {
        Sentry.captureMessage("Error caught in reportExecpting Function");
        Sentry.captureException(error);
    }
}

const bugPageReportToSentry = ({
    error=null,  //  The error object
    category='', // BUG PAGE :: <Where it came from>
    message='', // more info
    info=null, // the stack trace. Should tell use what compoenet crashed

}) => {
    try {
        Sentry.addBreadcrumb({
            category: category,
            message: message,
            level: 'fatal',
            data: info

        });
        if (error) { 
            Sentry.captureMessage(message)
            Sentry.captureException(error);
        };
    } catch(e){
        Sentry.captureMessage("ECI bugPageReportToSentry Function");
        Sentry.captureException(e);
    }
};

const catchBlockErrors = ({ error=null, fileFunction=''
}) => {
    try {
        const message = `Error caught in ${fileFunction}`;
        console.log(message, error);
        Sentry.captureMessage(message);
        Sentry.captureException(error);
    } catch(e){
        Sentry.captureMessage('ECI catchBlockErrors func');
        Sentry.captureException(e);
    };
};

export const reportFetchErrorToSentry=(jsonResult, token)=>{
    const tokenDecoded = jwtDecode(token);
    let userId = "User is not signed in";
    if(tokenDecoded && tokenDecoded.sub){
        userId = tokenDecoded.sub.split('|')[1];
    }

    const errorObj = {
        error: jsonResult,
        userId: userId
    }
    console.log('errorObj ', errorObj);
    const error = new Error(JSON.stringify(errorObj));
    Sentry.captureException(error);
}

export const reportGeneralErrorToSentry=(error, message, token) => {
    const tokenDecoded = jwtDecode(token);
    let userId = "User is not signed in";
    if(tokenDecoded && tokenDecoded.sub){
        userId = tokenDecoded.sub.split('|')[1];
    } else if(token === "No token prop"){
        userId = token;
    }
    const errorObj = {
        error: error.message,
        message: message,
        userId: userId
    }
    console.log('errorObj ', errorObj);
    const capturedError = new Error(JSON.stringify(errorObj));
    Sentry.captureException(capturedError);
}

export { reportAPIErrorToSentry, bugPageReportToSentry, catchBlockErrors };
